import moment from 'moment';
import {
  getSessionId,
  getUser,
  getAuthDataFromGlobalObject
} from 'shared/services';
import { JSON_CONTENT_TYPE } from '../../config';

export function getHeaders(
  overrideHeaders: Record<string, string> = {}
): Record<string, string> {
  const sessionId = getSessionId();
  const user = getUser();
  const { synaptic_device_id: synapticDeviceId, access_token: accessToken } =
    getAuthDataFromGlobalObject(['synaptic_device_id', 'access_token']);
  const silenceLogger = user && user.email && user.email.indexOf('qa+') > -1;

  return {
    'Content-Type': JSON_CONTENT_TYPE,
    ...(sessionId ? { 'session-id': sessionId } : {}),
    ...(silenceLogger ? { 'X-SILENCE-LOGGER': 'true' } : {}),
    ...(synapticDeviceId ? { 'synaptic-device-id': synapticDeviceId } : {}),
    ...(accessToken ? { 'access-token': accessToken } : {}),
    LOCAL_TIME: moment().format('YYYY-MM-DD[T]HH:mm:ss[.]SSSZ'),
    ...overrideHeaders
  };
}
