import { ThemeMode } from './provider';

export interface Color {
  25: string;
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export interface Colors {
  'cool-grey': Color;
  brand: Color;
  error: Color;
  warning: Color;
  success: Color;
  teal: Color;
  cyan: Color;
  blue: Color;
  purple: Color;
  pink: Color;
  rose: Color;
  orange: Color;
  'warm-grey': Color;
  white: string;
  black: string;
  transparent: string;
}

export type ColorProp = `${keyof Colors}.${keyof Color}`;

export interface TextColors {
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
  text6: string;
  brand: string;
  error: string;
  warning: string;
  success: string;
  on: string;
}

export interface IconColors {
  icon1: string;
  icon2: string;
  icon3: string;
  icon4: string;
  icon5: string;
  icon6: string;
  icon7: string;
  icon8: string;
  icon9: string;
  icon10: string;
  icon11: string;
  icon12: string;
  icon13: string;
  icon14: string;
  icon15: string;
  icon16: string;
  icon17: string;
  icon18: string;
  brand: string;
  error: string;
  warning: string;
  success: string;
  on: string;
}
export interface OutlineColors {
  outline1: string;
  outline2: string;
  outline3: string;
  brand: string;
  error: string;
  warning: string;
  success: string;
}

export interface SurfaceColors {
  surface1: string;
  surface2: string;
  surface3: string;
  surface4: string;
}

export type TextColorProps = `text.${keyof TextColors}`;

export interface AllColors extends Colors {
  text: { light: TextColors; dark: TextColors };
  icon: { light: IconColors; dark: IconColors };
  outline: { light: OutlineColors; dark: OutlineColors };
  surface: { light: SurfaceColors; dark: SurfaceColors };
}

export type WeightValue = 400 | 500 | 600 | 700;
export type StringWeightValue = '400' | '500' | '600' | '700';

export interface FontWeights {
  regular: WeightValue;
  medium: WeightValue;
  semibold: WeightValue;
  bold: WeightValue;
}

export interface Fonts {
  base: string;
  icons: string;
}

// Typescript doesn't support range of number as indexes so this is the
// best way to do this. keyof array returns indexes as string while we expect them
// to be numbers
export type FontSizeKeys = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export type LineHeightForms = 'single' | 'longform';
export type FontSizes = Array<number> & { [k in FontSizeKeys]: number };
export type IconSizeKeys = 0 | 1 | 2 | 3 | 4 | 5;
export type IconSizes = { [k in IconSizeKeys]: number };
export type FixedLineHeights = {
  [k in FontSizeKeys]: number | undefined;
};

export interface Typography {
  fontSizes: FontSizes;
  fontWeights: FontWeights;
  iconSizes: IconSizes;
  textStyles: TextStyles;
  fonts: Fonts;
}

export type BorderKeys = 0 | 1 | 2;
export type Borders = { [k in BorderKeys]: number };
export type RadiiKeys = 0 | 1 | 2 | 3;
export type Radii = { [k in RadiiKeys]: number };
export const SpacingsKeys = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30, 31
] as const;
export const OddSpacingsKeys = [
  0.5, 1.5, 2.5, 3.5, 4.5, 5.5, 6.5, 7.5, 8.5, 9.5, 10.5, 11.5, 12.5, 13.5,
  14.5, 15.5, 16.5, 17.5, 18.5, 19.5, 20.5, 21.5, 22.5, 23.5, 24.5, 25.5, 26.5,
  27.5, 28.5, 29.5, 30.5, 31.5
] as const;

export type Spacings = { [k in typeof SpacingsKeys[number]]: number };

export interface Spaces {
  space: Spacings;
  sizes: Spacings;
  minWidths: Spacings;
  maxWidths: Spacings;
  minHeights: Spacings;
  maxHeights: Spacings;
  heights: Spacings;
  radii: Radii;
  borders: Borders;
  baseSize: number;
  baseSpace: number;
  oddSpace: number[];
}

export interface Theme extends Spaces, Typography {
  colors: AllColors;
  colorPalette: Colors;
  themeName: string;
  themeMode: ThemeMode;
}

export interface AntDTheme {
  [k: string]: string | number;
}

export interface TextStyles {
  caps: Record<string, string>;
  ellipsis: Record<string, string>;
  wrapped: Record<string, string>;
  pointer: Record<string, string>;
  nowrap: Record<string, string>;
}

export type FONT_WEIGHT_TYPE = 'regular' | 'medium' | 'semi-bold' | 'bold';
export type BUTTON_COLOR = 'brand' | 'negative';

export type FONT_COLOR_TYPE = keyof TextColors;
