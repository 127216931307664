import { createGlobalStyle } from 'styled-components';
import { THEME_V2_CLASS } from 'theme-v2/constants';
import themeGet from '../utils/theme-get';

const GlobalStyle = createGlobalStyle`
  // For save as PDF in Company Tear Sheet in PortfolioIQ

  h1, h2, h3, h4, h5 , h6{
    margin: 0;
  }

  @media print {
    body { -webkit-print-color-adjust: exact; }
    .echarts-for-react { break-inside: avoid; break-before: auto; break-after: auto; }
  }

  div.${THEME_V2_CLASS} {
    // Global styles for v2 should be scoped by THEME_V2_CLASS
    font-family: 'BasierSquare',-apple-system,BlinkMacSystemFont,'Segoe UI',system-ui;
    * {
    font-family: 'BasierSquare',-apple-system,BlinkMacSystemFont,'Segoe UI',system-ui;
    }
    .clickable {
      cursor: pointer;
    }

    .ant-notification {
      margin-right: ${themeGet('space.4')}px;
      z-index: 99999;

      .ant-notification-notice {
        position: relative;
        border-radius: ${themeGet('radii.2')}px;
        padding: ${themeGet('space.3')}px ${themeGet('space.4')}px;
        background-color: ${themeGet('colors.warm-grey.900')};
        color: ${themeGet('colors.text.light.on')};
        box-shadow: unset;

        .ant-notification-notice-icon {
          position: absolute;
          height: 24px;
          width: 24px;
          top: 12px;
          left: 16px;
          margin-left: unset;
          font-size: unset;
          line-height: unset;
        }
        .ant-notification-notice-message {
          font-size: ${themeGet('fontSizes.2')}px;
          font-weight: ${themeGet('fontWeights.medium')};
          line-height: ${themeGet('longformLineHeights.2')}px;

          color: unset;
          margin-bottom: unset;
        }
        .ant-notification-notice-description {
          font-size: ${themeGet('fontSizes.1')}px;
          font-weight: ${themeGet('fontWeights.regular')};
          line-height: ${themeGet('longformLineHeights.1')}px;
          margin-top: ${themeGet('space.1')}px;
        }
        .ant-notification-notice-with-icon {
          .ant-notification-notice-message, .ant-notification-notice-description {
            margin-left: ${themeGet('space.8')}px;
            margin-right: ${themeGet('space.8')}px;

            padding-right: unset;
          }
        }
        .ant-notification-notice-btn {
          position: absolute;
          height: 24px;
          width: 24px;
          top: 12px;
          right: 16px;
          cursor: pointer;
          
          margin-left: unset;
          font-size: unset;
          line-height: unset;
          float: unset;
          margin-top: unset;
        }
        .ant-notification-notice-close, .ant-notification-notice-description:empty {
          display: none;
        }
      } 
    }
  }

  // Styles needed for dragging state of column header in people & company screener table
  .ag-dnd-ghost.people-analysis-drag-header {
    opacity: 0.7;
    display: flex;
    width: 240px;
    min-width: 120px;
    min-height: 36px;
    align-items: center;
    gap: ${themeGet('space.3')}px;
    background: ${themeGet('colors.cool-grey.50')};
    box-shadow: 0px 1px 2px 0px rgba(29, 42, 57, 0.10), 0px 3px 3px 0px rgba(29, 42, 57, 0.09), 0px 6px 4px 0px rgba(29, 42, 57, 0.05), 0px 11px 5px 0px rgba(29, 42, 57, 0.01), 0px 18px 5px 0px rgba(29, 42, 57, 0.00);
    cursor: move;
    .ag-dnd-ghost-icon {
      display: none;
    }
    border: 1px solid ${themeGet('colors.cool-grey.200')};
  }

  .ag-dnd-ghost.company-screener-drag-header{
    background: #fcfcfc;
    border: 1px solid ${themeGet('colors.cool-grey.200')};
    margin: ${themeGet('space.0')}px;
    padding-top:${themeGet('space.1')}px;
    padding-left:${themeGet('space.6')}px;
    padding-right:${themeGet('space.2')}px;
    padding-bottom:${themeGet('space.1')}px;
    font-size: ${themeGet('fontSizes.0')}px;
    font-weight: ${themeGet('fontWeights.medium')};
    transform: translateY(${themeGet('space.2')}px);
    z-index: 5;

    .ag-dnd-ghost-icon {
      display: none;
    }
  }

  .ag-dnd-ghost.ag-theme-next-styles {
    background: #fcfcfc;
    border-radius: ${themeGet('space.0')}px;
    box-shadow: none;
    border: ${themeGet('space.0')}px;
    margin: ${themeGet('space.0')}px;
    padding: ${themeGet('space.0')}px ${themeGet('space.2')}px;
    transform: translate(${themeGet('space.2')}px);
    z-index: 5;

    .ag-dnd-ghost-icon {
      margin-right: ${themeGet('space.0')}px;
    }
  }
  

  .padded-scrollbars {
    *::-webkit-scrollbar,
    *::-webkit-scrollbar-track,
    *::-webkit-scrollbar-track:hover {
      width: 8px;
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
    }
    *::-webkit-scrollbar-thumb,
    *::-webkit-scrollbar-thumb:hover {
      border: 2px solid transparent;
      border-radius: 4px;
      background-clip: content-box;
      background-color: ${themeGet('colors.cool-grey.200')};
    }
  }
  /* Animation for the skeleton */

  @keyframes tuxedo-v2-loading-animation {
    0% {
      background: linear-gradient(
        90deg,
        ${themeGet('colors.cool-grey.100')} 25%,
        ${themeGet('colors.cool-grey.25')} 50%,
        ${themeGet('colors.cool-grey.100')} 75%
      );
      background-size: 200% 100%;
      background-position: 200% 0;
    }
    100% {
      background: linear-gradient(
        90deg,
        ${themeGet('colors.cool-grey.100')} 25%,
        ${themeGet('colors.cool-grey.50')} 50%,
        ${themeGet('colors.cool-grey.100')} 75%
      );
      background-size: 200% 100%;
      background-position: -200% 0;
    }
  }

  .overflow-hide {
    overflow: hidden !important;
  }
`;

export default GlobalStyle;
